import React from "react"
import Style from "../styles/main.css"
import StyleResponsive from "../styles/main-responsive.css"
import HeaderDesktop from "../components/headerDesktop"
import HeaderMobile from "../components/headerMobile"
import Footer from "../components/footer"
import BannerPageComponent from "../components/sections/bannerPage"

export default function HypnoseTherapeutique() {
  return (
    <div className="irn-wrapper">
      <div className="irn-wrapper-inner-page">
        <HeaderDesktop />
        <HeaderMobile />
        <BannerPageComponent title="Hypnose therapeutique"/>
      </div>
      <div className="irn-content-page">
          <div className="irn-content-page_block bold">
              <h3>Mon protocole</h3>
            <p>
            Mon protocole concernant l’hypnose thérapeutique est le suivant : <br/>
            1ère séance : questions  - Acupression  - Hypnose de relaxation en fin de séance (10 mn)<br/>
            2ème séance : Hypnose 1h<br/>
            </p>
            <p>
            Je vais vous exposer en quelques mots le protocole que j’ai mis en place il y a quelques temps, un partenariat entre le corps et l’esprit qui je l’espère vous convaincra et vous permettra en deux séances de vous retrouver.<br/>
            Il se déroulera ainsi:
            </p>
            <span className="rounded">1</span>
            <p>Je vous invite tout d’abord à me contacter par mail pour vous présenter et m’exposer par écrit votre objectif en quelques lignes. Est-il lié à une phobie – un manque de confiance en soi – un deuil – une addiction ?</p>
            <span className="rounded">2</span>
            <p>Nous conviendrons ensuite ensemble de deux RDV espacés d’une semaine minimum.</p>
            <span className="rounded">3</span>
            <p>Lors de notre premier RDV, qui durera 1h,  je vous poserai quelques questions. Je vous inviterai ensuite à vous installer sur une table de massage pour une séance d’acupression (voir onglet « ACUPRESSION – Qu’est-ce que c’est ?» pour plus de précisions).<br/>
                J’exercerai des pressions sur des points d’acupuncture précis.<br/>Cette séance vise à une meilleure circulation de l’énergie selon la Médecine Traditionnelle Chinoise.<br/>En toute fin de séance l’hypnose s’invitera pour vous détendre et accentuer les bienfaits de la séance.<br/><br/>
            Vous observerez durant la semaine qui suit les effets, le ou les rêves marquants (l’inconscient peut effectivement se manifester)...<br/>et vous n’hésiterez pas à me les communiquer par mail.<br/><br/>
            Durant cette semaine de transition, de mon côté je préparerai votre séance d’hypnose avec les éléments que vous m’aurez communiqués c’est à dire les réponses aux questions posées durant la 1ère séance mais aussi les rêves marquants, les sensations physiques ressenties durant la séance d’acupression … tous ces éléments, que vous m’aurez fait parvenir au préalable par mail, me serviront pour répondre  parfaitement à votre demande d’objectif.</p>
            <span className="rounded">4</span>
            <p>La 2ème séance sera entièrement dédiée à l’hypnose<br/>(voir onglet « HYPNOSE » pour plus de précisions).<br/>Une séance qui durera 1h.</p>
          </div>
      </div>
      <Footer />
    </div>
    )
}
